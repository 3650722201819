import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const Wrapper = ({...props}) => {
    const navigate = useNavigate();
    const { auth, ready } = useAuth();

    useEffect(() => {
        if (auth.token == 'Unauthorized' && !ready) {
            navigate('/auth/signin');
        }

    }, [auth, ready]);

    return (
        (ready && auth.token) && <div className="d-flex flex-column">
            { props.children }
        </div>
    );
}

export default Wrapper;