import Wrapper from "../../layouts/Wrapper";
import ModuleItem from "./components/ModuleItem";
import icLogo from '../../assets/svg/ic_logo.svg';
import icHospital1 from '../../assets/svg/ic_hospital1.svg';
import icHospital2 from '../../assets/svg/ic_hospital2.svg';
import icHospital3 from '../../assets/svg/ic_hospital3.svg';
import icHospital4 from '../../assets/svg/ic_hospital4.svg';
import icGreenSinus from '../../assets/svg/ic_green_sinus.svg';
import icIdCard from '../../assets/svg/ic_id_card.svg';
import icBlueSyrigne from '../../assets/svg/ic_blue_syrigne.svg';
import icAtom from '../../assets/svg/ic_atom.svg';
import icBlueHeartTablet from '../../assets/svg/ic_blue_heart_tablet.svg';
import icDbCloud from '../../assets/svg/ic_db_cloud.svg';
import icDigitalBrain from '../../assets/svg/ic_digital_brain.svg';
import icDigitalDoc from '../../assets/svg/ic_digital_doc.svg';
import icDoctor from '../../assets/svg/ic_doctor.svg';
import icEKGMonitor from '../../assets/svg/ic_ekg_monitor.svg';
import icMedicalRecords from '../../assets/svg/ic_medical_records.svg';
import icHealthMobile from '../../assets/svg/ic_health_mobile.svg';
import icLongInvoice from '../../assets/svg/ic_long_invoice.svg';
import icMedicalBook from '../../assets/svg/ic_medical_book.svg';
import icPurpleVirus from '../../assets/svg/ic_purple_virus.svg';
import icRadioMonitor from '../../assets/svg/ic_radio_monitor.svg';
import icRedSyrigne from '../../assets/svg/ic_red_syrigne.svg';
import icSpeaker from '../../assets/svg/ic_speaker.svg';
import icWarehouse from '../../assets/svg/ic_warehouse.svg';
import icNews from '../../assets/svg/ic_news.svg';
import icSearchEngineWebSite from '../../assets/svg/ic_search_engine_web_site.svg';
import icDigitalSecurityEnterPinPasskeySecurityCodeSecurityPin from '../../assets/svg/ic_digital_security_enter_pin_passkey_security_code_security_pin.svg';
import icComputerTelevision from '../../assets/svg/ic_computer_television.svg';
import icChat from '../../assets/svg/ic_chat.svg';
import icMedicalMachine from '../../assets/svg/ic_medical_machine.svg';
import icStatistics from '../../assets/svg/ic_statistics.svg';
import icMedicalSymbol from '../../assets/svg/ic_medical_symbol.svg';
import { useTranslation } from "react-i18next";


const Dashboard = ({...props}) => {
    const { t } = useTranslation();

    const items = [
        { name: t('SERVICE_1'), icon: icHospital1, link: '/'},
        { name: t('SERVICE_2'), icon: icHospital2, link: '/'},
        { name: t('SERVICE_3'), icon: icHospital3, link: 'https://fiz2.alemtilsimat.com'},
        { name: t('SERVICE_4'), icon: icHospital4, link: 'https://fiz2.alemtilsimat.com'},
        { name: t('SERVICE_5'), icon: icGreenSinus, link: 'https://egmu.gov.tm'},
        { name: t('SERVICE_6'), icon: icAtom, link: '/'},
        { name: t('SERVICE_7'), icon: icIdCard, link: '/'},
        { name: t('SERVICE_8'), icon: icPurpleVirus, link: '/'},
        { name: t('SERVICE_9'), icon: icBlueSyrigne, link: '/'},
        { name: t('SERVICE_10'), icon: icMedicalRecords, link: '/'},
        { name: t('SERVICE_11'), icon: icRedSyrigne, link: '/'},
        { name: t('SERVICE_12'), icon: icWarehouse, link: 'https://ammar.alemtilsimat.com'},
        { name: t('SERVICE_13'), icon: icEKGMonitor, link: '/'},
        { name: t('SERVICE_14'), icon: icMedicalSymbol, link: '/'},
        { name: t('SERVICE_15'), icon: icLongInvoice, link: 'https://alemhasap.alemtilsimat.com'},
        { name: t('SERVICE_16'), icon: icStatistics, link: '/'},
        { name: t('SERVICE_17'), icon: icDoctor, link: '/'},
        { name: t('SERVICE_18'), icon: icMedicalMachine, link: '/'},
        { name: t('SERVICE_19'), icon: icMedicalBook, link: '/'},
        { name: t('SERVICE_20'), icon: icDigitalDoc, link: '/'},
        { name: t('SERVICE_21'), icon: icBlueHeartTablet, link: '/'},
        { name: t('SERVICE_22'), icon: icRadioMonitor, link: '/'},
        { name: t('SERVICE_23'), icon: icHealthMobile, link: '/'},
        { name: t('SERVICE_24'), icon: icChat, link: '/'},
        { name: t('SERVICE_25'), icon: icSpeaker, link: '/'},
        { name: t('SERVICE_26'), icon: icNews, link: '/'},
        { name: t('SERVICE_27'), icon: icSearchEngineWebSite, link: '/'},
        { name: t('SERVICE_28'), icon: icDigitalSecurityEnterPinPasskeySecurityCodeSecurityPin, link: '/'},
        { name: t('SERVICE_29'), icon: icDbCloud, link: '/'},
        { name: t('SERVICE_30'), icon: icDigitalBrain, link: '/'},
        { name: t('SERVICE_31'), icon: icComputerTelevision, link: '/'},
    ]
    
    return (
        <Wrapper loading={props.loading}>
            <div className="flex-column dashboard-bg">
                <div className="d-flex flex-row align-items-center justify-content-between px-5">
                    <span className="fs-1 bold py-4 fg-dark">{t('APP_NAME')}</span>
                    <img src={icLogo} className="wh-50" alt="logo" />
                </div>
                <div className="d-flex flex-column align-items-center col-md-12 mt-4">
                    <div className="d-flex flex-row">
                       {items.slice(0, 6).map((item) => { return ( <ModuleItem key={item.name} item={item}/>) })}
                    </div>
                    <div className="d-flex flex-row">
                       {items.slice(6, 12).map((item) => { return ( <ModuleItem key={item.name} item={item}/>) })}
                    </div>
                    <div className="d-flex flex-row">
                       {items.slice(12, 18).map((item) => { return ( <ModuleItem key={item.name} item={item}/>) })}
                    </div>
                    <div className="d-flex flex-row">
                       {items.slice(18, 24).map((item) => { return ( <ModuleItem key={item.name} item={item}/>) })}
                    </div>
                    <div className="d-flex flex-row">
                       {items.slice(24, 30).map((item) => { return ( <ModuleItem key={item.name} item={item}/>) })}
                    </div>
                    <div className="d-flex flex-row">
                       {items.slice(30, 36).map((item) => { return ( <ModuleItem key={item.name} item={item}/>) })}
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

export default Dashboard;