import { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";
import AxiosHelper from "../../api/AxiosHelper";
import ToastU from "../../components/ui/ToastU";
import Wrapper from "./Wrapper";
import icUser from './../../assets/svg/ic_user_lock_alt.svg';
import icEmail from './../../assets/svg/ic_email.svg';
import icLock from './../../assets/svg/ic_lock.svg';
import icEyeOpen from './../../assets/svg/ic_eye_open.svg';
import InputLabelIcon from "../../components/form/InputLabelIcon";
import { useTranslation } from "react-i18next";

const Login = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { auth, setAuth, ready } = useAuth();
    const usernameRef = useRef();
    
    const [username, setUsername] = useState(''); 
    const [password, setPassword] = useState('');

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        if (ready && !auth.token) usernameRef.current.focus();
    }, [ready]);

    useEffect(() => {
        if (auth != 'Unauthorized' && ready) {
            navigate("/");
        }
    }, [auth]);

    const handleLoginSubmit = (event) => {
        event.preventDefault();

        const data = { username, password }
        AxiosHelper.login(data).then(async(res) => {
            if (res.status == 200) {
                setAuth(res.data);
            }
        }).catch((err) => {
            if (!err?.response) {
                setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 401: setToast({ content: t('NOT_VERIFIED'), variant: 'danger', title: t('ERROR'), show: true }); break;
                case 402: setToast({ content: t('VALIDATION_ERROR'), variant: 'danger', title: t('ERROR'), show: true }); break;
                case 404: setToast({ content: t('USER_NOT_FOUND'), variant: 'warning', title: t('NOT_FOUND'), show: true }); break;
                case 420: setToast({ content: t('EMAIL_OR_PWD_NOT_ENTERED'), variant: 'warning', title: t('WARNING'), show: true }); break;
                default : setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        });
    }

    return (
        (!ready && auth.token == 'Unauthorized') && <Wrapper>
            <img src={icUser} className="wh-100 bg-white rounded-circle p-3"/>
            <span className="text-center fs-4 medium fg-dark mt-2">{t('SIGN_IN')}</span>
            <div className="container bg-light shadow-sm rounded-2 pt-3 pb-2 px-4 col-md-3 mt-2">
                <span className="text-center font-13 fg-gray">{t('ENTER_LOGIN_CREDENTIALS')}</span>
                <form onSubmit={handleLoginSubmit} className="mt-2" noValidate>
                    <InputLabelIcon 
                        formClass="" inputClass={'col-md-12'}
                        id={'email'} type={'email'} ref={usernameRef}
                        value={username} setValue={setUsername}
                        hint={t('USERNAME')} autoFocus={true} required={true}
                        label={t('USERNAME')} labelClass={'fg-dark'} icon={icEmail}
                    />
                    <InputLabelIcon 
                        formClass={'mt-3 mb-2'} inputClass={'col-md-12'}
                        id={'password'} type={'password'} ref={null}
                        value={password} setValue={setPassword}
                        hint={t('YOUR_PASSWORD')} autoFocus={false} required={true}
                        label={t('PASSWORD')} labelClass={'fg-dark'} icon={icLock} iconRight={icEyeOpen}
                    />
                    <button type="submit"
                        className="button-u bg-green-400 text-white font-14 border col-12 my-3 py-2 rounded-2"
                        disabled={(!username || !password)}>
                        {t('SIGN_IN')}
                    </button>
                </form>
            </div>
            <ToastU toast={toast} setToast={setToast}/>    
        </Wrapper>
    );
};

export default Login;