export default class Keys {
    // static USERNAME_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{5,31}$/;
    static USERNAME_REGEX = /^[a-zA-Z][a-zA-Z0-9-_.]{3,31}$/;
    // static EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    static EMAIL_REGEX = /^[a-zA-Z][a-zA-Z0-9-_.]{3,31}$/;
    static OTP_REGEX = /^[0-9]{2,5}$/;
    // static PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&]).{8,255}$/;
    static PASSWORD_REGEX = /^[\p{L}\p{N}\p{M}&.\s-]{3,255}$/u;
    static NAME_REGEX = /^[a-zA-Z0-9äöüňýçşžÄÖÜŇÝÇŞŽ&.\s-]{3,128}$/;
    static PHONE_NUMBER_REGEX = /^[6][1-5][0-9]{6}$/; // starts with 6, second digit in range(1-5), then any number
    static UNSIGNED_DOUBLE_REGEX = /^[0-9.]{0,14}$/;
    static UNSIGNED_INTEGER_REGEX = /^[0-9]{0,100}$/;
    static PHONE_CODE = "+993";

    static UNAUTHORIZED = "Unauthorized";
}